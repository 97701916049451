import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

function getEnvironment() {
  return process.env.NODE_ENV;
}

Sentry.init({
  environment: getEnvironment(),
  dsn: "https://134e25f5d2dc616fdfdc5941da461d1f@o4507342163083264.ingest.de.sentry.io/4507342167474256",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration(),
    // eslint-disable-next-line import/namespace
    Sentry.browserProfilingIntegration(),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
